import axios from './index'

/* 
 * get
 * @param {路径,参数对象}
 */
const get = async (url, query) => {
    return await axios.get(url, {
        params: query
    });
}

/* 
 * post
 * @param {路径,参数对象}
 */
const post = async (url, query) => {
    return await axios.post(url, query);
}

/* 
 * put
 * @param {路径,参数对象}
 */
const put = async (url, query) => {
    return await axios.put(url, query);
}

/* 
 * delete
 * @param {路径,参数对象}
 */
const Delete = async (url, query) => {
    return await axios.delete(url, query);
}

let Api = {};


/* 
 * 登录
 * @param {*}
 */
Api.login = async (query = {}) => await post("/admin/login", query);

/* 
 * 修改密码
 * @param {*}
 */
Api.modifyPwd = async (query = {}) => await post("/admin/modifyPwd", query);

export default {
    get, post, put, delete: Delete, ...Api
}

