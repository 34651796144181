<template>
    <div class="content-upload">
        <!-- 上传部分 -->
        <el-upload
            :action="request_url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :accept="accept"
            :name="name"
            :before-upload="customBeforeUpload"
        >
            <div class="avatar">
                <!-- 文件列表部分 -->
                <div
                    class="item-box"
                    :style="customStyle"
                    v-for="(item, index) in getList"
                    :key="index"
                >
                    <el-image
                        class="img"
                        v-if="getFileType(item) == 'image'"
                        :src="prefixPath + item"
                        fit="contain"
                    ></el-image>
                    <i v-else class="el-icon-folder-opened not-img"></i>
                    <div class="deleteBox" v-if="item">
                        <!-- 更换 -->
                        <i class="el-icon-refresh" @click="replace(index)"></i>
                        <!-- 删除 -->
                        <i
                            class="el-icon-delete"
                            @click.stop="uploadDelete(index)"
                        ></i>
                    </div>
                </div>
                <!-- 默认图片插槽 -->
                <slot>
                    <div class="image-box" :style="customStyle">
                        <!-- 如果地址存在且为不为多选并且为图片 -->
                        <el-image
                            class="img"
                            v-if="
                                !multiple &&
                                !getValueIsEmpty &&
                                getFileType(getValue) == 'image'
                            "
                            :src="prefixPath + getValue"
                            fit="contain"
                        ></el-image>
                        <!-- 如果不为多选且地址存在不是图片 -->
                        <i
                            v-else-if="!multiple && !getValueIsEmpty"
                            class="el-icon-folder-opened not-img"
                        ></i>
                        <!-- 为多选或为空 -->
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <!-- 不为多选且地址存在 -->
                        <div
                            class="deleteBox"
                            v-if="!multiple && !getValueIsEmpty"
                            @click.stop
                        >
                            <!-- 删除 -->
                            <i
                                class="el-icon-delete"
                                @click.stop="uploadDelete"
                            ></i>
                        </div>
                    </div>
                </slot>
            </div>
        </el-upload>
    </div>
</template>

<script>
/**
 * 上传文件工具
 * @description 本组件提供文件上传的功能，采用Elementui库的上传方法
 */
export default {
    name: "CustomUpload",
    data() {
        return {
            request_url: "", //文件上传请求基础地址
            //文件类型信息列表
            typeInfoList: {
                excel: ["xls", "xlsx"],
                word: ["doc","docx"],
                image: ["jpg", "jpeg", "png", "gif"],
            },
            //当前点击位置，多选时使用
            currentClickIndex: null,
        };
    },
    props: {
        // 父组件绑定值
        value: {
            type: [String, Array],
            default: null,
        },
        // 自定义样式
        customStyle: {
            type: Object,
            default: () => {
                return {
                    width: "150px",
                    height: "150px",
                };
            },
        },
        // 可上传的文件类型，具体值详看elementui官方，默认可上传所有文件
        accept: {
            type: String,
            default: "",
        },
        // 上传成功回调方法
        success: {
            type: Function,
            default: () => {},
        },
        // 上传失败回调方法
        error: {
            type: Function,
            default: () => {},
        },
        // 上传之前回调，需要返回布尔值 true 允许上传， false 不允许上传
        beforeUpload: {
            type: Function,
            default: () => true,
        },
        // 文件的前缀路径，服务器地址
        prefixPath: {
            type: String,
            default: "",
        },
        // 上传接口路径
        uploadPath: {
            type: String,
            default: "",
        },
        // 文件上传到后端的 form name值
        name: {
            type: String,
            default: "file",
        },
        //是否为多个文件
        multiple: {
            type: Boolean,
            default: false,
        },
        // 值输入处理
        enterHandle: {
            type: Function,
            default: (src) => src,
        },
        // 值输出处理
        outHandle: {
            type: Function,
            default: (src) => src,
        },
    },
    watch: {
        //监听值的变化
        value: {
            handler(val) {
                this.handlerValue();
            },
            immediate: true, // 是否立即执行
            deep: true, // 是否深度监听
        },
    },
    computed: {
        //获取值
        getValue() {
            let res = this.enterHandle(this.value);
            if(res === null) {
                if(this.multiple) {
                    if (res.length == 1 && res[0] == "") res = [];//如果值的第一个为空则设置为空数组
                }
                else res = "";
            } 
            return res;
        },
        //获取值是否为空
        getValueIsEmpty() {
            return this.getValue == null || this.getValue == "";
        },
        //获取文件列表
        getList() {
            if (this.multiple) return this.getValue;
            else return [];
        },
        //获取文件类型
        getFileType() {
            return (fileName) => {
                if (typeof fileName !== "string")
                    throw new Error("当前文件地址必须为字符串！");
                let file_split_list = fileName.split(".");
                let fileSuffix = file_split_list[file_split_list.length - 1]; //文件后缀
                for (let key in this.typeInfoList) {
                    let item = this.typeInfoList[key];
                    if (item.indexOf(fileSuffix) != -1) return key;
                }
            };
        },
    },
    methods: {
        //初始化
        init() {
            this.request_url = `${process.env.VUE_APP_BASE_URL}${this.uploadPath}?token=${this.$store.getters._getToken}`;
        },
        //处理值
        handlerValue() {
            let newVal = this.getValue;
            //如果是多选判断值是否符合规则
            if (this.multiple) {
                if (!Array.isArray(this.getValue))
                    throw new Error("当前传递值需要为数组类型！");
            } else {
                if (typeof newVal !== "string")
                    throw new Error("当前传递值需要为字符串类型！");
            }
        },
        //上传之前
        customBeforeUpload(file) {

            let file_split_list = file.name.split(".");
            if (file_split_list.length <= 1) {
                this.$notify({
                    title: "错误",
                    message: "文件不可用！",
                    duration: 3000,
                    showClose: false,
                    type: "error",
                });
                return false;
            }
            return this.beforeUpload(file, this.type);
        },
        //上传完成
        handleAvatarSuccess(res, file) {
            if (res.code == 200) {
                let res_out = null;
                if (this.multiple) {
                    res_out = this.getValue;
                    if (this.currentClickIndex != null) {
                        res_out[this.currentClickIndex] = res.data.src;
                        this.currentClickIndex = null;
                    } else res_out.push(res.data.src);
                } else res_out = res.data.src;
                this.$emit("input", this.outHandle(res_out));
                this.success(res.data.src);
            } else {
                this.error(res);
                this.$notify({
                    title: "错误",
                    message: res.msg,
                    duration: 3000,
                    showClose: false,
                    type: "error",
                });
            }
        },
        //删除
        uploadDelete(index = 0) {
            let res = null;
            if (this.multiple) {
                res = this.getValue;
                res.splice(index, 1);
            } else res = "";
            this.$emit("input", this.outHandle(res));
        },
        //更换
        replace(index = 0) {
            this.currentClickIndex = index;
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.content-upload {
    .avatar {
        display: flex;
        flex-wrap: wrap;

        .item-box {
            position: relative;
            margin-right: 10px;
            border-radius: 6px;
            margin-bottom: 10px;

            .deleteBox {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 99;
                border-radius: 6px;
                display: none;
                align-items: center;
                justify-content: center;

                .el-icon-delete {
                    font-size: 25px;
                    color: #fff;
                }

                .el-icon-refresh {
                    font-size: 25px;
                    color: #fff;
                    margin-right: 20px;
                }
            }

            .img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }

            .img:hover {
                border-color: #409eff;
            }

            &:hover .deleteBox {
                display: flex;
            }

            .not-img {
                font-size: 70px;
                color: #8c939d;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px dashed #ddd;
                border-radius: 6px;
            }
        }
        .image-box {
            width: 100%;
            height: 100%;
            border: 1px dashed #ddd;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            .img {
                width: 100%;
                height: 100%;
            }

            .not-img {
                font-size: 70px;
                color: #8c939d;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .el-upload:hover {
                border-color: #409eff;
            }

            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover .deleteBox {
                display: flex;
            }

            .deleteBox {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 99;
                border-radius: 6px;
                display: none;
                align-items: center;
                justify-content: center;

                .el-icon-delete {
                    font-size: 25px;
                    color: #fff;
                }
            }
        }
    }
}
</style>