import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import main from '@/views/main.vue'
import login from '@/views/login'
import NotFound from '@/views/404/index'

Vue.use(Router)

let router = new Router({
    mode: "history",
    routes: [
        // 添加 404 路由
        {
            path: '*',
            component: NotFound
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                title: "登录"
            }
        },
        {
            path: '/',
            name: 'adminIndex',
            component: main,
            meta: {
                title: "首页"
            },
            children: [
                {
                    path: "/",
                    component: () => import("@/views/modules/index"),
                    meta: {
                        title: "首页"
                    },
                },
                {
                    path: "/carousel",
                    component: () => import("@/views/modules/carousel"),
                    meta: {
                        title: "轮播图管理"
                    },
                },
                {
                    path: "/user",
                    component: () => import("@/views/modules/memorial"),
                    meta: {
                        title: "纪念馆信息管理"
                    },
                },
                {
                    path: "/notice",
                    component: () => import("@/views/modules/notice"),
                    meta: {
                        title: "公告管理"
                    },
                },
                {
                    path: "/userOrder",
                    component: () => import("@/views/modules/userOrder"),
                    meta: {
                        title: "单人订单管理"
                    }, 
                },
                {
                    path: "/teamUserOrder",
                    component: () => import("@/views/modules/teamUserOrder"),
                    meta: {
                        title: "团队订单管理"
                    }, 
                },
                {
                    path: "/calendar",
                    component: () => import("@/views/modules/calendar"),
                    meta: {
                        title: "日历管理"
                    }, 
                },
                {
                  path: "/statistics",
                  component: () => import("@/views/modules/statistics"),
                  meta: {
                      title: "预约统计"
                  }, 
              },
              {
                path: "/adminPhone",
                component: () => import("@/views/modules/adminPhone"),
                meta: {
                    title: "管理员手机号"
                }, 
            },
            ]
        }
    ]
});

/**
 * 路由守卫
 */
router.beforeEach(async (to, from, next) => {
    // 如果不是登录页面查看是否有token
    let token = store.getters._getToken;
    if (!token && to.path !== "/login") {
        next("/login");
    } else next();
});

export default router;