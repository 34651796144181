<template>
    <div class="content">
        <div class="back-box"></div>
        <div class="login-box">
            <div class="login-box-login">
                <div class="title">登录</div>
                <div class="from-box">
                    <div class="username input">
                        <el-input
                            type="text"
                            placeholder="请输入账号"
                            v-model="loginData.username"
                            maxlength="30"
                        >
                        </el-input>
                    </div>
                    <div class="password input">
                        <el-input
                            type="password"
                            placeholder="请输入密码"
                            maxlength="30"
                            v-model="loginData.password"
                            show-password
                        >
                        </el-input>
                    </div>
                    <div class="button">
                        <el-button type="primary" @click="login"
                            >登录</el-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "login",
    data() {
        return {
            base_url: "",
            loginData: {
                username: "",
                password: "",
            },
        };
    },
    methods: {
        setToken(tokenData) {
            this.$store.commit("set_token", tokenData.token); //向vuex内插入token
        },
        //登录
        login() {
            if (
                this.loginData.username == "") {
                this.$notify({
                    title: "错误",
                    message: "账号不能为空！",
                    duration: 3000,
                    showClose: false,
                    type: "error",
                });
                return;
            }
            if (
                this.loginData.password == ""
            ) {
                this.$notify({
                    title: "错误",
                    message: "密码不能为空！",
                    duration: 3000,
                    showClose: false,
                    type: "error",
                });
                return;
            }
            this.$api.login(this.loginData).then((res) => {
                if (res.code == 200) {
                    this.$notify({
                        title: "成功",
                        message: "欢迎回来！",
                        duration: 3000,
                        showClose: false,
                        type: "success",
                    });
                    this.setToken(res.data); //设置token
                    this.$router.push({path: '/',query:{username: res.data.user.name}});
                }
            });
        },
    },
    mounted() {
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/var.scss";

.content {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5;
    background-image: url("@/assets/images/login/back.jpg");
    background-size: cover;

    .back-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(
            to right,
            rgba(29, 75, 174, 0.1),
            rgba(79, 219, 146, 0.1)
        );
    }

    .login-box {
        max-width: 500px;
        min-width: 300px;
        width: 500px;
        padding-bottom: 40px;
        border-radius: 5px;
        background-color: $login-box-background-color;
        color: $login-box-color;
        position: relative;
        z-index: 99;

        & > div {
            width: 100%;
            height: 100%;

            .title {
                font-size: 30px;
                color: $login-box-color;
                text-align: center;
                height: 100px;
                line-height: 100px;
            }

            .from-box {
                min-width: 260px;
                max-width: 350px;
                width: 350px;
                margin: 0 auto;

                .input {
                    margin-bottom: 20px;

                    .el-input {
                        ::v-deep .el-input__inner {
                            color: $login-box-input-color;
                            background-color: transparent;
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid $login-box-input-color;

                            &::-webkit-input-placeholder {
                                color: $login-box-color;
                                font-size: 15px;
                            }
                        }
                    }
                }

                .button {
                    width: 100%;
                    padding-top: 10px;

                    .el-button {
                        width: 100%;
                    }
                }

                .switch {
                    margin-top: 10px;
                    color: $login-box-color;
                    text-align: center;
                    font-size: 14px;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>