<template>
    <div class="content">
        <h1>404</h1>
        <div class="btn-box">
            <button @click="back">返回上一页</button>
            <button @click="toLogin">去登录</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        back() {
            window.history.back();
        },
        toLogin() {
            this.$router.push("/login");
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/var.scss";

.content {
    h1 {
        padding-top: 150px;
        text-align: center;
        font-size: 40px;
        margin: 0;
    }

    .btn-box {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        button {
            padding: 10px 20px;
            background-color: $overall-hover-font-color;
            color: $overall-background-color;
            border: none;
            border-radius: 5px;

            &:first-child {
                margin-right: 50px;
            }
        }
    }
}
</style>